.masters {
  background: url("../img/mast-bg.jpg") center center no-repeat;
  background-size: cover;
  overflow: hidden; }
  .masters__footer {
    display: flex;
  }
  .masters__footer img {
    width: 100%;
    object-fit: cover;
  }
  .masters .container {
    position: relative; }
    @media (min-width: 1600px) {
      .masters .container {
        width: 1399px; } }
  .masters .social {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -23px; }
    @media (max-width: 767px) {
      .masters .social {
        margin-bottom: 0; } }
    @media (max-width: 767px) {
      .masters .social span {
        margin-bottom: 10px; } }
  .masters__footer .img-mob {
    display: none;
  }
  .masters__picture img {
    max-width: 100%;
  }
  .masters__wrap {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 120px 100px 120px 0px; }
  .masters__A_mob {
    display: none; }
    @media (max-width: 767px) {
      .masters__A_mob {
        display: block;
        width: 100%; }
        .masters__A_mob img {
          width: 100%;
          max-width: 310px;
          margin: auto;
          display: block; } }

  .masters__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 2;
    }
    @media (max-width: 1600px) {
      .masters__info {
        width: 353px;
        padding-top: 40px; } }
    @media (max-width: 992px) {
      .masters__info {
        width: 45%;
        margin-right: 4px;
        padding-top: 37px; } }
    @media (max-width: 767px) {
      .masters__info {
        width: 100%;
        text-align: center;
        padding-top: 0; } }
    .masters__info .btn-img {
      margin-left: auto;
      height: 59px;
      min-width: 265px;
      background-size: contain;
      background-image: url(../img/button-bg-red.svg);
      margin-right: auto; }
      @media (max-width: 992px) {
        .masters__info .btn-img {
          left: -50px; } }
      @media (max-width: 767px) {
        .masters__info .btn-img {
          left: 0; } }
    .masters__info .btn-img::after {
      display: none;
    }
    .masters__info .btn-img span {
      color: #fff;
    }
    .masters__info p {
      box-sizing: border-box;
      padding: 42px 44px;
      letter-spacing: 0.2px;
      background: url("../img/text-border.svg") top left no-repeat, url("../img/text-border2.svg") top right no-repeat;
      background-size: contain;
      margin-bottom: 51px;
      font-size: 20px;
      margin-top: 0;
      text-align: left; }
      @media (max-width: 1200px) {
        .masters__info p {
          margin-left: 0; } }
      @media (max-width: 1600px) {
        .masters__info p {
          font-size: 18px; } }
      @media (max-width: 992px) {
        .masters__info p {
          font-size: 18px;
          padding: 9px 17px 27px;
          margin-left: -16px;
          margin-bottom: 33px;
          max-width: 80%; } }
      @media (max-width: 767px) {
        .masters__info p {
          margin-left: auto;
          margin-right: auto;
          padding: 20px;
          font-size: 16px;
          margin-top: 35px;
          margin-bottom: 36px; } }
      @media (max-width: 480px) {
        .masters__info p {
          /* margin-left: -5px; */
          width: calc( 100% + 10px); } }

  .masters__info p span {
    max-width: 340px;
    padding: 0;
    display: inline-block;
  }


  .masters__title {
    color: #fff;
    font-size: 80px;
    font-weight: 400;
    line-height: 80px;
    text-align: left;
    text-transform: uppercase;
    font-family: "MachineC", serif;
    margin-bottom: 42px;
    padding-left: 23px;
    white-space: nowrap;
  }




  .masters__social {
    position: relative;
    z-index: 2;
    padding-bottom: 29px; }
    @media (max-width: 992px) {
      .masters__social {
        padding-bottom: 17px; } }
    @media (max-width: 767px) {
      .masters__social {
        z-index: 10;
        padding-top: 20px; } }


@media (max-width: 1599px) {
  .ad_league-container {
    gap: 50px;
    justify-content: space-between;
  }
  .masters .container {
    width: 100%;
    max-width: 1200px;
  }
  .masters__wrap {
    padding: 80px 0px 80px 0px;
  }
}

@media (max-width: 1150px) {
  .ad_league-container {
    grid-template-columns: 1fr 1fr;
  }
  .masters__title {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .ad_league {
    padding: 30px 0px 50px 0px;
    background-image: url(../img/ad_league-mob.jpg);
    background-position: center;
  }
  .ad_league .block-center {
    text-align: center;
    width: 100%;
  }
  .ad_league-container {
    display: block;
  }
  .ad_league-picture {
    text-align: center;
  }
  .ad__league-info__text p {
    font-size: 16px;
  }
  .ad_league-info {
    margin: 0px -20px;
  }
  .ad__league-info__text {
    padding: 0px 20px;
  }
  .masters__picture {
    display: none;
  }
  .masters__wrap {
    display: block;
    padding: 50px 0px;
  }
  .masters__title {
    font-size: 53px;
    text-align: center;
    line-height: 54px;
  }
  .masters__info {
    align-items: center;
  }
  .masters__footer .img-desc {
    display: none;
  }
  .masters__footer .img-mob {
    display: block;
  }
}
@media (max-width: 425px) {
  .ad_league {
    background-position: center -450px;
  }
}
