@font-face {
    font-family: 'MachineC';
    src: local('ITC Machine Cyrillic'), local('MachineC'),
        url('/fonts/MachineC/MachineC.woff2') format('woff2'),
        url('/fonts/MachineC/MachineC.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

