.adrenalineInfo {
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin: auto;
  padding: 20px;
  color: #fff;
  &__quest {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 20px;
    font-style: italic;
    letter-spacing: 2px;
    margin-bottom: 50px;
    &_icon {
      width: 11px;
      height: 11px;
      fill: #110f0c;
    }
    &_arrow {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      width: 23px;
      height: 23px;
      background-color: #fff;
      border-radius: 50%;
    }
    &.active {
      .adrenalineInfo__quest_icon {
        transform: rotate(180deg);
      }
    }
  }
}

.adrenalineBlock {
  display: none;
  h2 {
    background: #977C3E;
    font-size: 36px;
    background: linear-gradient(to right, #977C3E 0%, #D6CB82 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
    text-transform: none;
    font-style: normal;
    & + p {
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
  &__slider {
    position: relative;
    z-index: 0;
    max-width: 600px;
    margin: auto;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 500px;
      height: 300px;
      background-color: #a08747;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: .3;
      box-shadow: 0 0 120px 80px #a08747;
    }
  }
  &.active {
    display: block;
    animation-name: slideInUp;
    animation-duration: .5s;
    animation-fill-mode: both;
  }
}


.mb-20 {
  margin-bottom: 20px;
}


.adrenalineSlider {
  position: relative;
  max-width: 600px;
  &__item {
    max-width: 500px;
    border-radius: 20px;
    color: #fff;
    padding: 25px 50px;
    font-size: 20px;
    background-color: #110f0c;
    background: url('../img/news/card-bg.png') no-repeat;
    background-size: cover;
    img {
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 15px;
      text-transform: uppercase;
      font-weight: normal;
    }
    p {
      span {
        color: #ccaf63;
      }
    }
  }
  &-pagination {
    position: absolute;
    /* bottom: 20px; */
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
    & > * {
      &:not(:last-child) {
        margin-right: 13px;
      }
    }
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      transition: all .3s;
      border: 1px solid #fff;
      outline: none;
      opacity: 1;
      background: transparent;
      &.swiper-pagination-bullet-active {
        background: #fff;
      }
    }
  }
  &__arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);
    width: calc(100% + 80px);
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 23px;
    cursor: pointer;
    height: 23px;
    border-radius: 50%;
    background-color: #fff;
    &_icon {
      width: 11px;
      height: 10px;
      &__prev {
        padding-right: 1px;
      }
      &__next {
        padding-left: 2px;
      }
    }
    &.swiper-button-disabled {
      opacity: 0;
      cursor: default;
    }
  }
}



.adrenalineFilling {
  width: 100%;
  max-width: 500px;
  padding: 50px 30px 20px 30px;
  margin: auto;
  text-align: left;
  &__item {
    display: none;
    font-size: 20px;
    p {
      margin-top: 25px;
      margin-bottom: 15px;
      span {
          text-transform: uppercase;
      }
    }
    &.active {
      display: block;
      animation-name: slideInUp;
      animation-duration: .5s;
      animation-fill-mode: both;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__col {
    flex: calc(50% - 35px);
    li {
      margin-bottom: 5px;
      list-style: none;
    }
  }
  &__gold {
    color: #d4b567;
  }
}


@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} 



@media(max-width: 320px) {
  .adrenalineSlider {
    &__item {
      max-width: 60%;
    }
  }
}

@media(max-width: 375px) {
  .adrenalineSlider {
    &__item {
      max-width: 67%;
    }
  }
}





@media(max-width: 414px) {
  .adrenalineInfo {
    padding: 20px 0;
    &__quest {
      font-size: 17px;
      margin-bottom: 30px;
    }
  }
  .adrenalineBlock {
    h2 {
      font-size: 18px;
      line-height: normal;
      & + p {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    &__slider {
      padding: 20px;
      &:after {
        width: 250px;
        height: 250px;
      }
    }
  }
  .adrenalineSlider {
    &__item {
      max-width: 67%;
      font-size: 14px;
      img {
        margin-bottom: 20px;
      }
      h3 {
        font-size: 17px;
      }
    }
    &-pagination {
      bottom: -10px;
    }
    &__arrows {
      width: calc(100% + 20px);
    }
  }
  .adrenalineFilling {
    margin-top: 20px;
    padding: 20px 0;
    &__item {
      font-size: 17px;
      width: 100%;
      max-width: 280px;
      margin: auto;
    }
    &__col {
      li {
        font-size: 14px;
      }
    }
  }
}

.adrenalineFilling_gold {
  color: #ccaf63;
}