.bslider {
  border-top: 4px solid #2c2a29;
  border-bottom: 4px solid #2c2a29;

  background: url("../img/screen05_left_lines2.png") top left no-repeat, url("../img/screen05_right_lines2.png") right top repeat-y;
  /* background-size: auto 100%; */

  @media(max-width: 992px) {
    background-size: auto 70%;
    background-repeat: no-repeat;
  }

  @media(max-width: 680px) {
    background: none;
  }

  @media(max-width: $mediaMobUp) {
    background: url("../img/slider-grd-bg.png") center -100px no-repeat;
    background-size: 100%;
  }

  h2.choose-mobile {
    display: none;
    text-transform: none;
    text-align: center;
    margin: 40px auto 0 auto;
    padding: 0;
    font-size: 30px;

    @media(max-width: 500px) {
      display: block;
    }
  }

  &__wrap {
    padding-top: 92px;
    //padding-bottom: 100px;
    padding-bottom: 51px;

    @media(max-width: 992px) {
      padding-top: 50px;
    }

    @media(max-width: 480px) {
      /* padding-top: 37px; */
      padding-top: 7px;
      padding-bottom: 36px;
    }
  }

  &__btn {
    margin-top: 70px;
    display: flex;
    justify-content: center;


    @media(max-width: 680px) {
      margin-top: 44px;
    }
  }

  li[data-slid-btn] {
    cursor: pointer;
  }
}

.tabs {
  display: flex;
  justify-content: center;

  @media(max-width: 680px) {
    display: none;
  }

  > ul {
    display: flex;
    list-style: none;

    > li {
      position: relative;
      padding: 0 34.5px;
      cursor: pointer;

      @media(max-width: 992px) {
        padding: 0 15px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;

        &:after {
          display: none;
        }
      }

      &:hover, &.active {
        > span {
          color: $orange;
        }

        > ul {
          pointer-events: auto;
          opacity: 1;
          transform: translateY(0);
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        right: 0;
        width: 2px;
        height: 18px;
        transform: rotate(11deg);
        background: #d4b567;
      }

      span {
        font-size: 20px;
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: .2s;
        white-space: nowrap;

        &.active {
          color: $orange;
        }
      }

      ul {
        display: flex;
        flex-direction: column;

        list-style: none;

        padding-top: 4px;

        position: absolute;

        pointer-events: none;
        opacity: 0;
        transform: translateY(5px);
        transition: .2s;

        &:hover {
          pointer-events: auto;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        li {
          margin-bottom: 4px;
          position: relative;
          cursor: pointer;
          transform: translate3d(0, 0, 0);

          &:hover, &.active {
            span {
              color: $orange;
            }

            &:before {
              color: $orange;
            }
          }
          
          &:before {
            content: ">";
            position: absolute;
            top: 50%;
            left: -10px;
            transform: translateY(-50%);
            font-style: italic;
            font-size: 16px;
            transition: .2s;
          }
          
          span {
            font-size: 16px;
            font-style: italic;
            text-transform: uppercase;
            transition: .2s;
          }
        }
      }
    }
  }
}

.slider {
  margin-top: 100px;

  @media(max-width: 992px) {
    margin-top: 50px;
  }

  @media(max-width: 680px) {
    margin-top: 0;
  }

  .flickity-viewport {
    height: 510px;
    min-height: 580px;
  }

  .carousel-cell {
    z-index: 0;
    width: 100%;
    margin-right: 10px;
    transition: 0s !important;
    opacity: 0;
    
    &.is-selected {
      z-index: 1;
      opacity: 1 !important;
    }
  }

  .carousel-cell.is-selected &__info {
    transform: translate(0, 0);
    opacity: 1;
    transition: 1s;
  }

  .flickity-button {
    display: none;
  }

  .flickity-page-dots {
    display: none;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 680px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__prev, &__next {
    position: absolute;
    top: 50%;
    width: 23px;
    height: 23px;
    border: 0;
    padding: 0;
    transform: translateY(-50%);
    outline: none;
    background-color: transparent;
    transform-origin: center center;
    cursor: pointer;

    @media(max-width: 480px) {
      /* top: 0; */
      transform: translateY(0);
    }

    &:hover {
      svg {
        fill: $orange;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      transform: translate(-50%, -50%);
      background-color: transparent;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
      transition: .2s;
    }
  }

  &__prev {
    left: 0;

    @media(max-width: 480px) {
      left: 14px;
    }
  }

  &__next {
    right: 0;
    transform: translateY(-50%) rotate(180deg);

    @media(max-width: 480px) {
      /* top: 0; */
      transform: translateY(0) rotate(180deg);
    }

    @media(max-width: 480px) {
      right: 14px;
    }
  }

  &__360 {
    position: relative;
    width: 279px;

    @media(max-width: 680px) {
      margin-bottom: 40px;
    }

    @media(max-width: 480px) {
      margin-bottom: 48px;
      width: 100%;

      &.canvas-clicked {
        .slider__360-wrap:before, .slider__360-wrap:after {
          display: none;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;

      width: 50%;
      height: 40%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 110px 30px #937b3f;
      margin-top: -5%;

      @media(max-width: 992px) {
        width: 60%;
        height: 60%;
        border-radius: 50%;
        box-shadow: 0 0 50px 0 #937b3f;
      }

      @media(max-width: 480px) {
        width: 120px;
        box-shadow: 0 0 70px 0 #937b3f;
        margin-top: 15%;
      }
    }

    //.cloudimage-inner-box {
    //  &:after {
    //    content: "";
    //    display: block;
    //    position: absolute;
    //    bottom: 19px;
    //    left: -18px;
    //    width: 86px;
    //    height: 69px;
    //    background: url("../img/left360.svg") top left no-repeat;
    //  }
    //}

    h3 {
      display: none;

      text-align: center;
      text-transform: uppercase;
      font-size: 23px;
      font-style: italic;

      @media(max-width: 480px) {
        /* display: block; */
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 120px);
        text-align: center;

        letter-spacing: 1.3px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        margin-top: -2px;
      }
    }

    &-wrap {
      display: block;
      position: relative;
      //width: 174px;
      width: 190px;
      margin-left: auto;
      margin-right: auto;

      @media(max-width: 480px) {
        width: 140px;
        margin-top: 75px;
      }


      &:after {
        content: "";

        display: none;

        position: absolute;
        z-index: -1;
        bottom: 69px;
        left: 50%;

        width: 127%;
        height: 23px;
        transform: translateX(-50%);
        background: url("../img/top360.png") center top no-repeat;
        background-size: contain;

        @media(max-width: $mediaMobUp) {
          display: block;
        }
      }

      &:before {
        content: "";
        display: none;

        position: absolute;
        z-index: 1;
        bottom: 17px;
        left: 50%;

        width: 127%;
        height: 53px;
        transform: translateX(-50%);
        background: url("../img/bottom360.png") center top no-repeat;
        background-size: contain;

        @media(max-width: $mediaMobUp) {
          display: block;
        }
      }

      i {
        display: none;
        position: absolute;
        bottom: 19px;
        left: 50%;
        width: 127%;
        //height: 71px;
        height: 90px;
        transform: translateX(-50%);
        margin-left: 1px;

        @media(max-width: 480px) {
          display: block;
        }

        svg {
          display: none;
          width: 100%;
          height: 100%;
          fill: url(#MyGradient);
        }
      }
    }

    > i {
      display: flex;
      justify-content: center;
      padding-top: 14px;

      height: 58px;

      @media(max-width: 480px) {
        display: none;
      }

      svg {
        width: 62px;
        height: 34px;
        fill: url(#MyGradient);
      }
    }
  }

  &__info {
    box-sizing: border-box;
    //width: 412px;
    width: 470px;
    padding-left: 70px;

    transform: translateX(-50px);
    opacity: 0;
    transition-delay: .5s;
    transition: 0s;

    @media(max-width: 768px) {
      padding-left: 40px;
      transform: translateX(-20px);
    }

    @media(max-width: 680px) {
      padding-left: 0;
      width: 100%;
    }

    @media(max-width: 480px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      transform: translate(0, 0);
    }

    h3 {
      font-size: 36px;
      margin-top: 0;
      letter-spacing: -0.9px;
      margin-bottom: 10px;

      @media(min-width: 900px) {
        white-space: nowrap;
      }

      @media(max-width: 900px) {
        font-size: 30px;
      }

      @media(max-width: 480px) {
        font-size: 24px;
        order: 1;
        margin-bottom: 16px;
        padding-right: 10px;
      }

      @media(max-width: 360px) {
        font-size: 23px;
      }

      span {
        white-space: nowrap;
      }
    }

    p {
      margin-top: 0;

      @media(max-width: 480px) {
        order: 3;
        width: 100%;
      }
    }

    .but_buy {
      display: none;
      @media(min-width: 768px) {
        display: block;
        margin-top: 44px;
        cursor: pointer;
      }
    }

    ul {
      list-style: none;
      display: flex;
      margin-top: 69px;

      @media(max-width: 480px) {
        order: 2;
        margin-top: 0;

        margin-bottom: 19px;
        position: relative;
        top: -3px;
      }

      li {
        display: flex;
        align-items: flex-end;
        margin-right: 20px;

        @media(max-width: 480px) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }
        
        &.max {
          svg {
            @media(max-width: 480px) {
              height: 33px;
            }
          }
        }

        &.middle {
          svg {
            height: 39px;

            @media(max-width: 480px) {
              height: 28px;
            }
          }
        }

        &.min {
          svg {
            width: 17px;
            height: 33px;

            @media(max-width: 480px) {
              height: 25px;
            }
          }
        }

        &.current i {
          position: relative;

          &:after {
            content: "";
            position:absolute;
            left: 5%;
            top: 5%;
            width: 90%;
            height: 90%;
            box-shadow: 0 0 20px 1px #937b3f;
          }
        }
      }

      svg {
        display: block;
        width: 21px;
        height: 45px;
        fill: $orange;

        @media(max-width: 480px) {
          width: 14px;
          height: 31px;
        }
      }

      span {
        font-size: 20px;
        padding-left: 14px;
        line-height: 1;
        display: inline-block;
        color: $orange;

        @media(max-width: 480px) {
          padding-left: 8px;
          font-size: 14px;
        }
      }
    }
  }
}

.adrenalineInfo {
  .but_buy_mobile {
    display: none;
    @media(max-width: 767px) {
      display: block;
      margin: 30px auto;
      cursor: pointer;
    }
  }
}