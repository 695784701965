.bvideo {
  position: relative;
  height: 100vh;

  @media(max-width: 768px) {
    height: 68vw;
  }

  @media(max-width: 480px) {
    height: 338px;
  }

  //.plyr__controls {
  //  display: none;
  //}

  video, .plyr {
    width: 100%;
    height: 100%;
  }

  //&:after {
  //  content: "";
  //  display: block;
  //  position: absolute;
  //  z-index: 1;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  background: url("../img/video-bg.jpg") center center no-repeat;
  //  background-size: cover;
  //  //pointer-events: none;
  //  //opacity: .5;
  //}

  &__play {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 112px;
    height: 112px;
    transform: translate(-50%, -50%);
    cursor: pointer;

    @media(max-width: 480px) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      svg {
        fill: #000;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      transition: .2s;
      fill: #fff;
    }
  }

  &.play &__play, &.play:after {
    opacity: 0;
    pointer-events: none;
  }

  iframe {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

.plyr {
  .plyr__control.plyr__control--overlaid[data-plyr="play"] {
    width: 112px;
    height: 112px;
    background-color: transparent;
    background: url("../img/play.svg") center center no-repeat;
    filter: invert(100);

    svg {
      display: none;
    }

    @media(max-width: 480px) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      opacity: .6;
    }
  }
}
