.ad_league {
  background-image: url(../img/ad-league.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0px 30px 0px;
}
  
.ad_league-container {
  box-sizing: border-box;
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: grid;
  grid-template-columns: max-content minmax(auto, 630px);
  gap: 240px;
  align-items: center;

}
.ad_league-picture img {
  max-width: 100%;
}
@media screen and (min-width: 1600px){
  .ad_league-container {
    max-width: 1399px;
  }

}

.ad_league-video {
  width: 100%;
  height: 310px;
  margin-bottom: 46px;
}

.ad_league-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.ad__league-info__text {
  margin: 0px 0px 52px 0px;
}
.ad__league-info__text p {
  font-family: 'DIN Pro Cond';
  text-shadow: -2px 2px 13px #541608;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
}

.ad__league-info__text p + p {
  margin-top: 20px;
}

.btn-yellow {
  display: inline-block;
  padding: 15px 62px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  font-family: 'DIN Pro Cond';
  font-size: 22px;
  text-decoration: none;
  background-image: url(../img/button-bg-yellow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 1599px) {
  .ad_league-container {
    gap: 50px;
    justify-content: space-between;
  }
  .masters .container {
    width: 100%;
    max-width: 1200px;
  }
  .masters__wrap {
    padding: 80px 0px 80px 0px;
  }
}
@media (max-width: 1150px) {
  .ad_league-container {
    grid-template-columns: 1fr 1fr;
  }
  .masters__title {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .ad_league {
    padding: 30px 0px 50px 0px;
    background-image: url(../img/ad_league-mob.jpg);
    background-position: center;
  }
  .ad_league .block-center {
    text-align: center;
    width: 100%;
  }
  .ad_league-container {
    display: block;
  }
  .ad_league-picture {
    text-align: center;
  }
  .ad__league-info__text p {
    font-size: 16px;
  }
  .ad_league-info {
    margin: 0px -20px;
  }
  .ad__league-info__text {
    padding: 0px 20px;
  }
  .masters__picture {
    display: none;
  }
  .masters__wrap {
    display: block;
    padding: 50px 0px;
  }
  .masters__title {
    font-size: 53px;
    text-align: center;
    line-height: 54px;
  }
  .masters__info {
    align-items: center;
  }
  .masters__footer .img-desc {
    display: none;
  }
  .masters__footer .img-mob {
    display: block;
  }
}
@media (max-width: 425px) {
  .ad_league {
    background-position: center -450px;
  }
}

@media (min-width: 768px) {
  .ad_league-video {
    margin-left: -40px;
  }
}