$headerMediaTabUp: 992px;
$headerMediaTab: 760px;
$headerMediaMobUp: 480px;

.header {

  min-height: 2000px;

  position: relative;
  overflow: hidden;
  background-size: contain;
  margin-top: -241px;

  @media(max-width: $headerMediaTabUp) {
    margin-top: -138px;
    padding-bottom: 119px;
  }

  @media(max-width: $headerMediaTab) {
    margin-top: 0;
    padding-bottom: 114px;
  }

  @media(max-width: $headerMediaMobUp) {
    background: none;
  }

  .social {
    position: absolute;
    z-index: 10;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.loaded {
  .screen__text {
    opacity: 1 !important;
    p {
      opacity: 1 !important;
    }
  }

  .screen__img {
    opacity: 1 !important;
  }

  .header {
    @media(min-width: $headerMediaTab) {
      background: url("../img/screens1-4_bg_v32.jpg") top center repeat-y;
    }
  }
}

.screen {
  position: relative;
  /* content-visibility: auto; */
  /* contain-intrinsic-size: 1000px; */

  img {
    display: block;
    max-width: 100%;
  }

  .parallax {
    position: absolute;
  }

  .container {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);

    @media(max-width: $headerMediaTab) {
      position: static;
      width: 100%;
      transform: translate(0, 0);
    }
  }

  &__text {
    position: absolute;

    h2 {
      @media(max-width: $headerMediaTabUp) {
        font-size: 40px;
      }

      @media(max-width: $headerMediaMobUp) {
        font-size: 43px;
      }
    }

    h3 {
      font-size: 30px;
      line-height: 1;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;

      @media(max-width: $headerMediaTabUp) {
        font-size: 21px;
      }

      @media(max-width: $headerMediaMobUp) {
        font-size: 21px;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 300;
      margin-top: 0;
      letter-spacing: 0.3px;
      margin-bottom: 17px;

      @media(max-width: $headerMediaTabUp) {
        font-size: 16px;
        margin-bottom: 14px;
      }

      @media(max-width: $headerMediaMobUp) {
        font-size: 16px;
        margin-bottom: 10px;
      }

      b {
        font-weight: 500;
      }
    }

    .hashtag {

    }
  }

  &-1 {
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    @media(max-width: $headerMediaTab) {
      display: block;
      margin-top: -20%;
    }

    @media(max-width: $mediaTab - 1) {
      margin-top: 0;
    }

    .glob {
      @media(max-width: $headerMediaTabUp) {
        position: relative;
        right: -11.6%;
        width: 80%;
        margin-left: auto;
      }

      @media(max-width: $headerMediaTab) {
        transform: translateX(13%);
      }
    }

    .h1anim {
      @media(max-width: $headerMediaTabUp) {
        margin-top: 28px;
        margin-bottom: 13px;
      }

      @media(max-width: $headerMediaTab) {
        display: none;
      }
    }

    .section__h1 {
      display: none;

      @media(max-width: $headerMediaTab) {
        display: block;
        position: absolute;
        top: 3%;
        left: 20px;
        width: 38.3%;
      }

      @media(max-width: $headerMediaMobUp) {
        left: 17px;
      }
    }

    .parallax {
      right: 10.4%;
      bottom: 17.8%;

      @media(max-width: $headerMediaTabUp) {
        right: 1.2%;
        bottom: 17.5%;
        width: 406px;
      }

      @media(max-width: $headerMediaTab) {
        top: 37%;
        right: 1%;
        bottom: inherit;
        width: 66%;
      }

      @media(max-width: $headerMediaMobUp) {
        //top: 37%;
      }
    }

    .screen__img {
      position: relative;
    }

    .screen__text {
      position: absolute;
      width: 370px;
      padding-top: 25.3%;

      @media(max-width: $headerMediaTab) {
        position: static;
        padding-top: 0;
        width: 100%;
        margin-top: -9px;
      }

      @media(max-width: $headerMediaMobUp) {
      }

      img {
        margin-bottom: 82px;

        @media(max-width: $headerMediaTab) {
          display: none;
        }
      }

      h3 span {
        line-height: 1;
      }

      h3+h3 {
        margin-bottom: 15px;

        @media(max-width: $headerMediaMobUp) {
          margin-bottom: 9px;
        }
      }

      .hashtag {
        //margin-top: -17px;
        display: block;

        @media(max-width: $headerMediaMobUp) {
          display: inline-block;
          padding-left: 4px;
          //margin-top: -10px;
        }
      }

      p {
        opacity: 0.01;
      }
    }
  }

  &-2 {
    margin-top: -17px;
    z-index: 4;

    @media(max-width: $headerMediaTabUp) {
      margin-top: 1.7%;
    }

    @media(max-width: $headerMediaTab) {
      margin-top: 0;
    }

    .screen__img {
      opacity: 0.01;
      @media(max-width: $headerMediaTab) {
        margin-top: 29px;
      }
    }

    .parallax {
      bottom: 31.9%;
      left: 17.4%;

      @media(max-width: $headerMediaTabUp) {
        bottom: 28.2%;
        left: 10.5%;
        width: 363px;
      }

      @media(max-width: $headerMediaTab) {
        top: 8.8%;
        right: 5%;
        bottom: inherit;
        left: inherit;
        width: 65%;
      }
    }

    .glob {
      @media(max-width: $headerMediaTabUp) {
        width: 83%;
        margin-left: -8.4%;
      }

      @media(max-width: $headerMediaTab) {
        width: 126%;
        max-width: inherit;
      }
    }

    .screen__text {
      opacity: 0.01; /* 1 when DOM loaded */
      position: absolute;
      top: 27.3%;
      right: 3.3%;
      width: 330px;

      @media(max-width: $headerMediaTabUp) {
        top: 29%;
        right: 7.8%;
        width: 206px;
      }

      @media(max-width: $headerMediaTab) {
        position: static;
        padding-top: 30px;
        width: 100%;
      }

      @media(max-width: $headerMediaMobUp) {
        padding-top: 12px;
      }

      .hashtag {
        @media(max-width: $headerMediaTabUp) {
          margin-bottom: 3px;
        }

        @media(max-width: $headerMediaTab) {
          margin-bottom: 5px;
        }
      }

      h2 {
        margin-top: -4px;
        margin-bottom: 8px;

        @media(max-width: $headerMediaTabUp) {
          margin-bottom: 4px;
        }

        @media(max-width: $headerMediaMobUp) {
          margin-bottom: 10px;
        }
      }

      p {
        @media(max-width: $headerMediaTabUp) {
          font-size: 14px;
        }
      }
    }
  }

  &-3 {
    margin-top: -42.3%;
    display: flex;
    justify-content: flex-end;
    z-index: 4;

    @media(max-width: $headerMediaTab) {
      margin-top: -50%;
      display: block;
    }

    .screen__img {
      opacity: 0.01;
      @media(max-width: $headerMediaTab) {
        display: flex;
        justify-content: flex-end;

        .glob {
          height: 150vw;
          object-fit: contain;
          object-position: right top;
        }
      }
    }

    .parallax {
      right: 13%;
      bottom: 0.9%;

      @media(max-width: $headerMediaTabUp) {
        right: 13.2%;
        bottom: 0.6%;
        width: 372px;
      }

      @media(max-width: $headerMediaTab) {
        top: 35.5%;
        right: 10%;
        bottom: inherit;
        left: inherit;
        width: 73%;
      }
    }

    .glob {
      @media(max-width: $headerMediaTabUp) {
        width: 55%;
        margin-left: auto;
      }

      @media(max-width: $headerMediaTab) {
        width: 74.4%;
        max-width: inherit;
        margin-right: -9.5%;
      }
    }

    .container {
      @media(max-width: $mediaTab - 1) and (min-width: $mediaMobUp + 1) {
        transform: translateY(-100%);
      }
    }

    .screen__text {
      opacity: 0.01; /* 1 when DOM loaded */
      position: absolute;
      bottom: 31%;
      left: 1.9%;
      width: 310px;

      @media(max-width: $headerMediaTabUp) {
        bottom: 17%;
        left: 35px;
        width: 257px;
      }

      @media(max-width: $headerMediaTab) {
        position: static;
        margin-top: -6px;
        width: 100%;
      }

      .hashtag {
        @media(max-width: $headerMediaTabUp) {
          margin-bottom: 4px;
        }

        @media(max-width: $headerMediaTab) {
          margin-bottom: 5px;
        }
      }

      h2 {
        margin-top: -6px;
        margin-bottom: 9px;

        @media(max-width: $headerMediaTabUp) {
          margin-bottom: 4px;
        }
      }

      p {

      }
    }
  }

  &-4 {
    margin-top: -15%;
    z-index: 2;

    @media(max-width: $headerMediaTabUp) {
      margin-top: 0;
    }

    @media(max-width: $headerMediaTab) {
      margin-top: 0;
      display: block;
    }

    .screen__img {
      opacity: 0.01;
      margin-top: 15px;
    }

    .parallax {
      bottom: 0;
      left: 18.5%;

      @media(max-width: 1300px) {
        left: 5%;
      }

      @media(max-width: $headerMediaTabUp) {
        left: 6.9%;
        width: 330px;
        margin-bottom: -3%;
      }

      &.desktop {
        @media(max-width: $headerMediaTabUp) {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @media(max-width: $headerMediaTabUp) {
          display: inline-block;
        }
      }

      @media(max-width: $headerMediaTab) {
        top: 22.5%;
        right: 16%;
        bottom: inherit;
        left: inherit;
        width: 67%;
      }
    }

    .glob {
      @media(max-width: $headerMediaTabUp) {
        width: 57%;
        margin-top: -30.7%;
        margin-left: -7%;
      }

      @media(max-width: $headerMediaTab) {
        width: 74.4%;
        max-width: inherit;
      }
    }

    .screen__text {
      opacity: 0.01; /* 1 when DOM loaded */
      position: absolute;
      top: 35.3%;
      right: 2.9%;
      width: 336px;

      @media(max-width: $headerMediaTabUp) {
        top: 52.7%;
        right: 6.9%;
        width: 213px;
      }

      @media(max-width: $headerMediaTab) {
        position: static;
        margin-top: 35px;
        width: 100%;
      }

      h2 {
        margin-top: -6px;
        margin-bottom: 9px;

        @media(max-width: $headerMediaTabUp) {
          margin-bottom: 4px;
        }

        @media(max-width: $headerMediaTab) {
          margin-bottom: 10px;
        }
      }

      .hashtag {
        margin-top: -17px;

        @media(max-width: $headerMediaTabUp) {
          margin-bottom: 4px;
        }

        @media(max-width: $headerMediaTab) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
