$menuMediaTab: 760px;
$menuMediaTabUp: 992px;

.menu {
  position: sticky;
  z-index: 100;
  top: 0;
  height: 82px;
  background-color: #000;
  //margin-top: 159px;

  margin-top: 0;

  @media(max-width: $menuMediaTabUp) {
    height: 60px;
  }

  @media(max-width: $menuMediaTab) {
    margin-top: 0;
    height: 70px;
  }

  @media(max-width: 480px) {
    height: 47px;
  }

  &.active {
    .menu__nav {
      pointer-events: auto;
      opacity: 1;

      li {
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0);
      }

      @media(max-width: 480px) {
        padding-top: 47px;
      }
    }

    .button-menu {
      &:after {
        top: 50%;
        left: 50%;
        width: 140%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:before {
        top: 50%;
        left: 50%;
        width: 140%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      span {
        width: 0;
        opacity: 0;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    height: inherit;
    padding-left: 50px;
    padding-right: 50px;

    @media(max-width: $menuMediaTabUp) {
      padding-left: 22px;
      padding-right: 22px;
    }

    @media(max-width: $menuMediaTab) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__logo {
    width: 115px;
    height: 40px;

    img {
      height: inherit;
    }

    @media(max-width: $menuMediaTabUp) {
      width: 85px;
      height: 30px;
    }

    @media(max-width: 480px) {
      width: auto;
      height: 26px;
    }
  }

  &__nav {
    margin-left: auto;
    margin-right: auto;
    //transform: translateX(-115px);

    @media(max-width: $menuMediaTab) {
      margin-left: auto;
      margin-right: 0;
    }

    @media(max-width: $menuMediaTab) {
      box-sizing: border-box;
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 70px 20px 20px 0;
      background-color: #000;
      transition: .2s;

      padding-bottom: 0;

      pointer-events: none;
      opacity: 0;
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      @media(max-width: $menuMediaTab) {
        flex-direction: column;
        justify-content: center;
        height: 100%;
        overflow: auto;
      }
    }

    li {
      position: relative;

      @media(max-width: $menuMediaTab) {
        transform: translateX(-10px);

        transition: .5s;
        margin-bottom: 34px;
        pointer-events: none;
        opacity: 0;
        transition-delay: .2s;
        padding-left: 20px;

        text-align: center;
      }

      @media(max-height: 420px) {
        margin-bottom: 20px;
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:last-child {

        @media(max-width: $menuMediaTab) {
          margin-bottom: 0;
        }

        &:after {
          display: none;
        }
        a {
          margin-right: 0;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        right: 0;
        width: 2px;
        height: 18px;
        transform: rotate(11deg);
        background: $orange;

        @media(max-width: $menuMediaTabUp) {
          top: 5px;
          right: 0;
          width: 1px;
          height: 15px;
        }

        @media(max-width: $menuMediaTab) {
          display: none;
        }
      }
    }

    button {
      border: 0;
      padding: 0;
      background: transparent;
      cursor: pointer;
      outline: none;
    }

    a, button {
      position: relative;
      margin: 0 34px;
      transition: .2s;
      font-size: 20px;
      text-transform: uppercase;
      text-decoration: none;
      font-style: italic;
      color: inherit;
      font-weight: 500;
      letter-spacing: 1.6px;

      @media(max-width: $menuMediaTabUp) {
        font-size: 17px;
        margin: 0 27px;
        letter-spacing: 1.5px;
      }

      @media(max-width: $menuMediaTab) {
        margin: 0 15px;
      }

      @media(max-width: $menuMediaTab) {
        font-size: 28px;
        margin: 0;
      }

      &.active {
        color: $orange;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  &__btn {

    display: none;
    position: relative;
    cursor: pointer;

    @media(max-width: $menuMediaTab) {
      display: block;
      margin-left: auto;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 250%;
      transform: translate(-50%, -50%);
    }
  }
}

.button-menu {
  display: block;
  position: relative;
  width: 21px;
  height: 16px;
  cursor: pointer;

  &:after, &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $orange;
    transition: .2s;
  }

  &:after {
    top: 0;
  }

  &:before {
    bottom: 0;
  }

  &.active {
    &:after {
      top: 50%;
      left: 50%;
      width: 140%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      top: 50%;
      left: 50%;
      width: 140%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    span {
      width: 0;
      opacity: 0;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    transform: translate(-50%, -50%);
    background-color: $orange;
    transition: .2s;
  }
}
