
/* eslint no-use-before-define: 0 */

.popup {
  width: 100%;
  max-width: 1250px;
  font-family: Arial, sans-serif;
  background-color: #131313;
  border-radius: 20px;
  overflow: visible;
  padding: 40px 60px 80px;
  .swiper-pagination {
    bottom: 50px;
    left: 100px;
    .swiper-pagination-bullet {
      background-color: #a1a1a1;
      opacity: 1;
      outline: none;
      &:not(:last-child) {
          margin-right: 10px;
      }
      &.swiper-pagination-bullet-active {
          background-color: #fff;
      }
    }
  }
  .fancybox-close-small {
    display: none;
  }
  &__close {
    position: absolute;
    z-index: 999;
    right: 30px;
    bottom: calc(100% + 20px);
    &_icon {
      fill: #d4b567;
      width: 30px;
      height: 30px;
    }
  }
  
  h2 {
    font-size: 27px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    margin-bottom: 40px;
    color: #fff;
  }
}

.sliderShop {
  &__items {
    display: flex;
  }
  &__loading {
    display: none;
    position: relative;
    width: 40px;
    height: 40px;

    &:after {
      content: " ";
      box-sizing: border-box;
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 4px;
      border: 16px solid #d4b567;
      border-color: #d4b567 transparent #d4b567 transparent;
      animation: lds-hourglass 1.2s infinite;
      opacity: 0.7;
    }
  }
  &__empty, &__error {
    display: none;
    width: 100%;
  }
  &__item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    max-width: 260px;
    height: 255px;
    color: #131313;
    padding: 30px 35px 20px;
    background-color: #fff;
    border-radius: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
    img {
      margin-bottom: 30px;
      height: 45px;
    }
    h3 {
      margin-bottom: 20px;
      margin-top: auto;
      font-size: 27px;
      font-weight: normal;
      line-height: normal;
    }
    .button, .button:link, .button:visited, button {
      background-color: #d4b567;
      border-radius: 3px;
      text-transform: uppercase;
      text-decoration: none;
      color: #000;
      border: none;
      cursor: pointer;
      outline: none;
      font-weight: bold;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      padding: 0 18px;
    }
  }
  &__arrows {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 1220px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 30px;
    height: 30px;
    transition: all .3s;
    &_icon {
      width: 22px;
      height: 22px;
      fill: #fff;
    }
    &.swiper-button-disabled {
      opacity: .5;
    }
  }
  &__status {
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 22px;
    &:after {
      content: '';
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #0e9641;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
    &.not {
      &:after {
          background-color: #d9262e;
      }
    }
  }
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@media(max-width: 414px) {
  .popup__close {
    right: 10px;
    bottom: calc(100% + 10px);
  }
  .popup__close_icon {
    width: 20px;
    height: 20px;
  }
  .popup {
    padding: 30px 10px 60px;
    h2 {
      font-size: 23px;
      text-align: center;
      margin-bottom: 30px;
    }
    .swiper-pagination {
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        &:not(:last-child) {
          margin-right: 10px;
        }
        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
      }
    }
  }
  .sliderShop {
    &__loading {
      margin: 0 auto;
    }
    &__empty, &__error {
      text-align: center;
    }
    &__items {
      flex-wrap: wrap;
    }
    &__item {
      max-width: calc(50% - 7.5px);
      flex: calc(50% - 7.5px);
      align-items: center;
      height: 220px;
      padding: 20px 25px 15px;
      margin-bottom: 15px;
      &:not(:last-child) {
        margin-right: 15px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      img {
        margin-bottom: 15px;
        max-width: 100%;
        height: auto;
      }
      h3 {
        font-size: 23px;
      }
    }
    &__arrows {
      top: auto;
      bottom: 18px;
      max-width: 35%;
    }
    &__arrow {
      &_icon {
        width: 15px;
        height: 15px;
      }
    }
    &__status {
      margin-bottom: 10px;
    }
  }
}

@media(max-width: 380px) {
  .sliderShop {
    &__item {
      button {
        font-size: 14px;
        height: 25px;
        padding: 0 12px;
        line-height: 25px;
      }
    }
    &__status {
      font-size: 12px;
    }
    
  }
}

@media(max-width: 320px) {
  .sliderShop {
    &__item {
      height: 160px;
      img {
        height: 30px;
      }
      h3 {
        font-size: 14px;
      }
      button {
        font-size: 12px;
        height: 20px;
        padding: 0 10px;
        line-height: 20px;
      }
    }
    &__status {
      font-size: 10px;
    }
  }
}