.gamefuel {
  background: url("../img/game-fuel.jpg") center center no-repeat;
  background-size: cover;

  @media(max-width: $mediaMobUp) {
    background: url("../img/gamefuel-mobile-bg.png") center center no-repeat;
    background-size: cover;
  }

  &__wrap {
    padding-top: 42px;
    padding-bottom: 33px;

    @media(max-width: $mediaTabUp) {
      padding-top: 46px;
      padding-bottom: 39px;
    }

    @media(max-width: $mediaTab - 1) {
      padding-top: 4px;
    }

    @media(max-width: $mediaMobUp) {
      padding-bottom: 25px;
    }

    h2 {
      font-family: "MachineC", serif;
      font-size: 76px;
      text-align: center;
      letter-spacing: 1.2px;
      line-height: 1.1;
      margin-bottom: 71px;
      font-style: normal;

      @media(max-width: $mediaTabUp) {
        font-size: 46px;
        letter-spacing: 0.8px;
        margin-bottom: 41px;
      }

      @media(max-width: $mediaTab - 1) {
        display: none;
      }
    }
  }

  &__grid {
    display: flex;
    align-items: center;

    @media(max-width: $mediaTab - 1) {
      display: block;
    }
  }

  &__img {

    display: flex;
    flex: 1;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    padding-right: 50px;
    margin-top: -106px;
    opacity: 1 !important;
    max-width: 452.5px;

    @media only screen and (max-width: 767px) {
      margin: 0 auto;
    }
    &::before {
      content: '';
      width: 100%;
      display: block;
      padding-top: 230px;
    }
    &__wrapper {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      right: 50px;
      max-height: 452px;

      @media only screen and (max-width: 767px) {
        right: 0;
        height: 250px;
      }
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        transform: translateY(var(--top-translate));
      }
    }
    &__1 {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 20px;
      width: 248px;
      overflow: hidden;

      @media only screen and (max-width: 767px) {
        top: 30px;
        height: auto;
        width: 154px;
      }

      @media only screen and (max-width: 1439px) {
        top: 45px;
        height: auto;
        width: 184px;
      }
    }
    &__2 {
      width: 80%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 107px;
      left: 48%;
      transform: translate(-51.5%, 0);
      width: 320px;
      overflow: hidden;

      @media only screen and (max-width: 767px) {
        top: 85px;
        width: 192px;
      }

      @media only screen and (max-width: 1439px) {
        top: 112px;
        height: auto;
        width: 235px;
      }
    }
    &__3 {
      width: 72%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 175px;
      left: 50%;
      transform: translate(-39%, 0);
      width: 266px;
      overflow: hidden;

      @media only screen and (max-width: 767px) {
        top: 123px;
        left: 49.3%;
        width: 165px;
      }

      @media only screen and (max-width: 1439px) {
        top: 158px;
        height: auto;
        width: 198px;
      }
    }

    @media(max-width: $mediaTabUp) {
      padding-right: 9px;
      margin-top: -40px;
    }

    @media(max-width: $mediaTab - 1) {
      flex-direction: column;
      padding-right: 0;
      margin-top: 0;
    }

    h2 {
      display: none;

      @media(max-width: $mediaTab - 1) {
        display: block;
        text-align: center;
        font-size: 32px;
        margin-bottom: 28px;
        margin-top: -5px;
        font-style: normal;
      }

      @media(max-width: $mediaMobUp) {
        margin-bottom: 19px;
      }
    }
  }

  &__center {
    position: relative;
    width: 175px;

    @media(max-width: $mediaTabUp) {
      width: 139px;
    }

    @media(max-width: $mediaTab - 1) {
      width: 100%;
      margin-bottom: 39px;
    }

    @media(max-width: $mediaMobUp) {
      display: block;
      width: 164px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 31px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      top: 50%;
      right: 20px;
      width: 20px;
      height: 80%;
      border-radius: 100%;
      transform: translateY(-50%);
      box-shadow: 0 0 40px 30px #000000;

      @media(max-width: $mediaTab - 1) {
        left: 50%;
        width: 50px;
        border-radius: 0;
        //transform: translate(-50%, -50%);
      }
    }

    img {
      position: relative;
      z-index: 2;
      width: 100%;

      @media(max-width: $mediaTab - 1) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 150px;
      }

      @media(max-width: $mediaMobUp) {
        width: 100%;
      }
    }
  }

  &__text {
    flex: 1;
    padding-left: 50px;
    margin-top: -34px;

    opacity: 1 !important;
    overflow: hidden;

    @media(max-width: $mediaTabUp) {
      padding-left: 9px;
      margin-top: 15px;
    }

    @media(max-width: $mediaTab - 1) {
      width: 100%;
      padding-left: 0;
      margin-top: 0;
    }

    h3 {
      font-family: "MachineC", serif;
      font-size: 55px;
      letter-spacing: 1.8px;
      line-height: 1;
      margin-bottom: 16px;
      padding-left: 28px;

      @media(max-width: $mediaTabUp) {
        font-size: 38px;
        padding-left: 20px;
      }

      @media(max-width: $mediaTab - 1) {
        padding-left: 0;
        display: none;
      }
    }

    p {
      margin-top: 0;
      padding-left: 28px;

      @media(max-width: $mediaTabUp) {
        font-size: 16px;
        padding-left: 20px;
      }

      @media(max-width: $mediaTab - 1) {
        padding-left: 0;
      }

      @media(max-width: $mediaMobUp) {
        font-size: 14px;
      }
    }
  }
}


@media screen {
  /*custom*/
  html:not(.no-js) [data-aos=fade-right-max] {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  html:not(.no-js) [data-aos=fade-left-max] {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
