.footer {
  background: url("../img/screen12_left.png") top left no-repeat, url("../img/screen12_right.png") right bottom no-repeat;
  background-size: auto 100%;

  @media(max-width: 992px) {
    //background-size: au;
  }

  @media(max-width: 992px) {
    background-size: 30%, 40%;
    background-position: top left, 129% 100%;
  }

  @media(max-width: 680px) {
    background-size: 30%, 40%;
    background-position: top left, 129% 100%;
  }

  &__wrap {
    padding-top: 67px;
    padding-bottom: 40px;

    @media(max-width: 768px) {
      padding-top: 62px;
      padding-bottom: 19px;
    }

    h2 {
      text-align: center;
      font-style: italic;
      margin-bottom: 83px;

      @media(max-width: 450px) {
        span {
          max-width: 250px;
          margin-left:40px;
        }
      }

      @media(max-width: 375px) {
        span {
          max-width: 250px;
          margin-left:20px;
        }
      }

      @media(max-width: 360px) {
        span {
          max-width: 250px;
          margin-left:10px;
        }
      }

      @media(max-width: 320px) {
        span {
          max-width: 250px;
          margin-left:0px;
        }
      }

      @media(max-width: 768px) {
        margin-bottom: 28px;
      }
    }
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 113px;
    padding: 0 10.8%;

    @media(max-width: 1024px) {
      padding: 0;
    }

    @media(max-width: 768px) {
      margin-bottom: 50px;
    }

    @media(max-width: 680px) {
      flex-direction: column;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media(max-width: 768px) {
        &:not(:last-child) {
          margin-bottom: 37px;
        }
      }

      b {
        font-size: 90px;
        font-style: italic;
        display: inline-block;
        padding: 0 10px;

        @media(max-width: 992px) {
          font-size: 77px;
          line-height: 1;
          margin-bottom: 6px;
        }
      }

      svg {
        display: inline-block;
        //width: 100%;
        fill: url(#MyGradient);
        padding: 0 10px;
        font-size: 90px;
        font-weight: 700;
        font-style: italic;
        font-family: inherit;
        text-align: center;

        text {
          display: inline-block;
        }
      }

      span {
        font-size: 22px;
        line-height: 1;
        display: block;
        text-transform: uppercase;

        @media(max-width: 992px) {
          font-size: 20px;
        }

        @media(max-width: 768px) {
          font-size: 15px;
          letter-spacing: 0.7px;
        }
      }
    }
  }

  &__social {
    .social {
      span {
        margin-bottom: 15px;

        @media(max-width: 768px) {
          margin-bottom: 19px;
        }
      }
    }
  }
}

.text-gradient {
  color: #977c3e;

  @supports (-webkit-background-clip: text) {
    background: linear-gradient(90deg, #977c3e 40%, #fefcac);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  background-color: #000;

  padding-top: 12px;
  padding-bottom: 10px;

  @media(max-width: 768px) {
    flex-direction: row;
    justify-content: center;

    padding-top: 9px;
    padding-bottom: 8px;
  }
  
  span {
    text-align: center;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 2px;

    @media(max-width: 768px) {
      font-size: 11px;
      display: inline-block;
    }
  }
}