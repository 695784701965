$mediaHDPlus: 1600px;
$mediaTabUp: 992px;
$mediaTab: 768px;
$mediaMobUp: 480px;
$h1animRotate: 25deg;
$h1animRotate2: -25deg;

h1, h2, h3, h4, h5, h6, ul {
  margin: 0;
  padding: 0;
}

//.container {
//    @include container();
//    @include sm(max-width, rem(510));
//    @include md(max-width, rem(690));
//    @include lg(max-width, rem(930));
//    @include xl(max-width, rem(1110));
//}

:root, html {
  font-family: "DIN Pro Cond", serif;
  font-size: 20px;
  color: #fff;
  line-height: normal;
}

body {
  background: #000000 url("../img/screens1-4_bg_v3.jpg") repeat-y top center;
  background-size: contain;

  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  &.block-scroll {
    @media(max-width: 768px) {
      overflow: hidden;
    }
  }
}

.nomobile {
  @media(max-width: 768px) {
    display: none;
  }
}

.container {
  box-sizing: border-box;
  width: 1120px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: 1120px) {
    width: 100%;
  }

  @media(max-width: $mediaTabUp) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @media(max-width: 760px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.svg-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

h2 {
  font-weight: bold;
  font-style: italic;
  font-size: 60px;
  text-transform: uppercase;
  margin-top: 0;

  @media(max-width: 768px) {
    font-size: 36px;
    line-height: 1;
  }
}

p {
  font-size: 20px;

  @media(max-width: 992px) {
    font-size: 18px;
    line-height: 1.22;
  }

  @media(max-width: 480px) {
    font-size: 14px;
  }

  b {
    font-weight: bold;
    display: inline;
    //padding-left: 4px;
    padding-right: 4px;
  }

  span {
    display: inline;
    padding-right: 4px;
  }
}

a {
  color: inherit;
}

.btn {
  text-decoration: none;
  outline: none;
  display: inline-flex;
  align-items: center;

  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    svg {
      transform: translateX(5px) scale(1.1) rotate(180deg);
    }
  }

  &#{&}--orange {
    span {
      color: $orange;
    }

    svg {
      fill: $orange;
    }
  }

  &#{&}--white {
    span {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }

  &#{&}--hover-orange {
    &:hover {
      span {
        color: $orange;
      }

      svg {
        fill: $orange;
      }
    }
  }

  &#{&}--down {
    svg {
      transform: rotate(270deg);
    }

    &:hover {
      svg {
        transform: translateX(5px) scale(1.1) rotate(270deg);
      }
    }
  }

  span {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1;
    color: #000;
    margin-right: 8px;
    transition: .2s;
    font-weight: 500;

    @media(max-width: $mediaTabUp) and (min-width: $mediaMobUp + 1) {
      font-size: 17px;
      //font-style: italic;
      //letter-spacing: 1.4px;
      letter-spacing: 0;
      //font-weight: 400;
    }

    @media(max-width: $mediaMobUp) {
      letter-spacing: 0;
      //font-weight: 400;
    }
  }

  svg {
    width: 23px;
    height: 23px;
    transform: rotate(180deg);
    transition: .2s;
    color: #000;
    transform-origin: center center;

    @media(max-width: $mediaTabUp) and (min-width: $mediaMobUp + 1) {
      width: 20px;
      height: 20px;
    }
  }
}

.btn-img {
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;
  min-width: 250px;
  height: 47px;

  transition: .2s;

  padding-left: 20px;
  padding-right: 20px;

  text-decoration: none;

  background: url("../img/btnimg.svg") center center no-repeat;
  background-size: cover;

  @media(max-width: $mediaTabUp) and (min-width: $mediaMobUp - 1) {
    min-width: 181px;
    height: 33px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    height: 40%;
    transform: translate(-50%, -50%) skew(10deg);
    transition: .2s;
    box-shadow: 0 0 50px 0 rgba(#77aafc, 1);
  }

  span {
    font-size: 22px;
    line-height: 1;
    font-weight: bold;
    color: #000;

    @media(max-width: $mediaTabUp) and (min-width: $mediaMobUp - 1) {
      font-size: 17px;
    }
  }

  &:hover {
    &:after {
      box-shadow: 0 0 0 0 rgba(#77aafc, 1);
    }
  }
}


.social {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-bottom: 18px;

    @media(max-width: $mediaTabUp) {
      font-size: 14px;
      letter-spacing: 0.3px;
      margin-bottom: 16px;
    }

    @media(max-width: $mediaTab - 2) {
      font-size: 14px;
      margin-bottom: 19px;
    }
  }

  &#{&}--gradient {
    a {
      &:hover {
        svg {
          fill: url(#MyGradient);
        }
      }
    }
  }

  ul {
    display: flex;
    list-style: none;

    li {
      display: inline-block;

      a {
        display: block;
        width: 30px;
        height: 30px;
        margin-left: 9px;
        margin-right: 9px;

        @media(max-width: $mediaTabUp) {
          width: 25px;
          height: 25px;
        }

        &:hover {
          svg {
            fill: $blue;
          }
        }

        svg {
          width: 100%;
          height: 100%;
          fill: #fff;
          transition: .2s;
        }
      }
    }
  }
}

.hashtag {
  color: $orange;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  @extend .text-gradient;
  font-size: 30px;
  display: inline-block;

  @media(max-width: 992px) {
    font-size: 22px;
  }

  &#{&}--simple {
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    letter-spacing: -0.7px;
    background: none;
    color: $orange;
    -webkit-text-fill-color: $orange;

    @media(max-width: $mediaTabUp) {
      font-size: 16px;
    }

    @media(max-width: 480px) {
      font-size: 14px;
    }
  }
}

//h1anim
.h1anim {
  position: relative;
  width: 333px;
  height: 495px;
  padding-top: 71px;

  @media(max-width: $mediaTabUp) {
    width: 238px;
    height: 331px;
    padding-top: 21px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 5px;
    height: 100%;
    transform: translateX(-50%) rotate($h1animRotate);
    background-color: #fff;
    margin-left: -9px;
    margin-top: -10px;
    background: linear-gradient(0, #a6822d, #eddd8c, #a6822d);
    will-change: transform;

    @media(max-width: $mediaTabUp) {
      width: 3px;
      margin-left: -16px;
      margin-top: -32px;
    }
  }

  @at-root body.domloaded &, &.animate {
    .h1anim__text {
      visibility: visible;
      transform: rotate($h1animRotate2) translateX(0%);
      will-change: transform;
    }
  }

  span, em {
    font-style: italic;
    text-transform: uppercase;
    font-size: 128px;
    padding-right: 20px;
    font-weight: bold;
    letter-spacing: -6px;
    line-height: 0.77;

    @media(max-width: $mediaTabUp) {
      font-size: 81px;
      letter-spacing: -3.3px;
      line-height: 0.75;
    }
  }

  &__left, &__right {
    display: inline-block;
    width: 50%;

    @media(max-width: $mediaTabUp) {
      width: 44.5%;
    }
  }

  &__left {
    overflow: hidden;

    padding: 62px 0 0 6px;
    transform: rotate($h1animRotate);
    will-change: transform;

    @media(max-width: $mediaTabUp) {
      padding: 43px 0 0 7px;
      margin-top: -9px;
      margin-left: 2px;
    }

    .h1anim__text {
      will-change: auto;
      visibility: hidden;
      transform: rotate($h1animRotate2) translateX(150%);
      will-change: transform;
      transition: .5s;
    }

    span, em {
      color: #a6822d;

      @supports (-webkit-background-clip: text) {
        background: linear-gradient(90deg, #a6822d 40%, #eddd8c, #a6822d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    span {

    }

    em {
      margin-left: 53px;

      @media(max-width: $mediaTabUp) {
        margin-left: 31px;
      }
    }
  }

  &__right {
    position: absolute;
    top: 241px;
    right: -8px;
    overflow: hidden;
    transform: rotate($h1animRotate);
    will-change: transform;
    padding-left: 22px;
    padding-right: 30px;
    padding-bottom: 60px;

    @media(max-width: $mediaTabUp) {
      top: 127px;
      width: 51.6%;
      padding-left: 12px;
      padding-bottom: 39px;
    }

    .h1anim__text {
      will-change: auto;
      visibility: hidden;
      transform: rotate($h1animRotate2) translateX(-150%);
      will-change: transform;
      transition: .5s;
    }

    em {
      margin-left: -82px;

      @media(max-width: $mediaTabUp) {
        margin-left: -52px;
      }
    }
  }
}


//=====================
.buttonup {
  display: none;
  position: fixed;
  z-index: 100;
  right: 10px;
  bottom: 72px;
  width: 32px;
  height: 32px;
  transform: translateY(100%);
  transition: .5s;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @media(max-width: 580px) {
    display: block;
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  //&:hover {
  //  opacity: .5;
  //}

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    fill: #ffffff;
  }
}

.text-up {
  display: block;
  --top-translate: 200%;
  transform: scaleY(1.5);
  will-change: transform;
  overflow: hidden;

  body & {
    background: transparent;
    -webkit-background-clip: unset;
    color: transparent;
  }

  &_flex {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  span {
    display: block;
    background: transparent;
    -webkit-background-clip: unset;
    position: relative;
    overflow: hidden;
    line-height: 1.3;
    color: transparent;

    &::before{
      content: attr(data-text);
      position: absolute;
      transform: translateY(var(--top-translate));
      line-height: 1.1;
    }

    &.text-up_gradient::before {
      background: linear-gradient(90deg, #977c3e 40%, #fefcac);
      -webkit-background-clip: text;
      will-change: transform;
    }

    &.text-up_head::before {
      color: #ffffff;
    }
    &.text-up_head--black::before {
      color: #000000;
    }
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    transform: translateY(var(--top-translate));
  }
  &_img {
    height: 75%;

    &--2nd {
      height: 26%;
      position: absolute;
      bottom: 20%;
      left: 50%;
      body & {
        transform: translate(-50%, var(--top-translate));
      }
    }
  }
}
