@import "../../node_modules/flickity/css/flickity";
//@import "../../node_modules/flickity-fade/flickity-fade";
@import "../../node_modules/aos/dist/aos";
@import "../../node_modules/plyr/dist/plyr";

@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";
@import "vendor/libs";
@import "base/fonts";
@import "base/general";
@import "../blocks/components/components";
@import "../blocks/modules/modules";