.stadium {
  //background: url("../img/stadium-bg.jpg") top center no-repeat;
  //background-size: cover;
  position: relative;
  padding-top: 152px;
  padding-bottom: 312px;
  overflow: hidden;
  background-color: #000000;

  @media(max-width: $mediaTab) {
    background-position: top center;
    padding-top: 123px;
    padding-bottom: 142px;
  }

  &__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 110%;
      height: 110%;
      object-fit: cover;
      margin-top: -5%;
      margin-left: -5%;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 500px;
    color: #000;

    margin-left: auto;
    margin-right: auto;

    @media(max-width: 640px) {
      width: 100%;
    }

    h2 {
      font-weight: bold;
      font-style: italic;
      margin-bottom: 17px;

      @media(max-width: $mediaTab) {
        margin-bottom: 15px;
      }
    }

    p {
      margin-top: 0;
      text-align: center;
      margin-bottom: 26px;

      @media(max-width: $mediaTab) {
        margin-bottom: 23px;
      }
    }

    .btn {

    }
  }

  &-img {
    display: flex;

    @media(max-width: 580px) {
      flex-direction: column;
    }

    picture {
      display: block;
      flex: 1;
      //height: 30vw;
      height: 441px;
      overflow: hidden;

      @media(min-width: $mediaHDPlus) {
        height: 478px;
      }

      @media(max-width: $mediaTab) {
        height: 294px;
      }

      @media(max-width: 580px) {
        height: auto;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: 1s;
        filter: grayscale(1);

        @media(max-width: $mediaTab - 1) {
          filter: grayscale(0);
        }

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
}
